import request from '@/utils/request';
export var fileTransferHeaders = {};
export var defaultBannerData = {
  id: 0,
  enabled: true,
  name: '',
  desktopImage: '',
  mobileImage: '',
  description: '',
  priority: 0,
  validFrom: undefined,
  validTill: undefined,
  link: undefined,
  page: undefined,
  params: undefined
};
export var getBanners = function getBanners(params) {
  return request({
    url: '/banners',
    method: 'get',
    params: params
  });
};
export var getBannerById = function getBannerById(id) {
  return request({
    url: "/banners/".concat(id),
    method: 'get'
  });
};
export var updateBanner = function updateBanner(id, data) {
  return request({
    url: "/banners/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteBanner = function deleteBanner(id) {
  return request({
    url: "/banners/".concat(id),
    method: 'delete'
  });
};
export var createBanner = function createBanner(data) {
  return request({
    url: '/banners/',
    method: 'post',
    data: data
  });
};